




































import { Component, Vue } from 'vue-property-decorator'
import Dropdown from 'vue-simple-search-dropdown'
import { Mapping } from './interfaces/mapping'

@Component({
  components: {
    Dropdown
  }
})
export default class App extends Vue {
  private mappings: Mapping[] = []
  private currentSelection: Mapping = { id: -1, name: '' }
  private robotConfiguration: Mapping[] = []
  private result = ''
  private error = ''

  private showloginbox = false
  private username = localStorage.getItem('username') ?? ''
  private password = localStorage.getItem('password') ?? ''

  private mounted () {
    this.axios.defaults.headers.common.Authorization = 'Basic ' + btoa(this.username + ':' + this.password)
    this.getModules()
  }

  private getModules () {
    this.axios.get('modules.json').then((response) => {
      this.mappings = response.data
      this.error = ''
    }).catch((error) => {
      console.error(error)
      if (error.response.status === 401) {
        this.error = 'invalid or missing credentials. please sign in!'
        this.showloginbox = true
      } else {
        this.error = 'error: api not available. please come back later!'
      }
    })
  }

  private togglePW () {
    const pwInput : HTMLInputElement | null = document.querySelector('input[placeholder=password]')
    if (pwInput) {
      pwInput.type = pwInput.type === 'password' ? 'text' : 'password'
    }
  }

  private signIn () {
    this.showloginbox = false
    localStorage.setItem('username', this.username)
    localStorage.setItem('password', this.password)
    this.axios.defaults.headers.common.Authorization = 'Basic ' + btoa(this.username + ':' + this.password)
    this.getModules()
    return false
  }

  private updateSelection (selection: Mapping) {
    this.currentSelection = selection
  }

  private addModule () {
    if (this.currentSelection.id > -1) {
      this.robotConfiguration.push(this.currentSelection)
      this.result = ''
      this.error = ''
    }
  }

  private removeModule (index: number) {
    if (index > -1) {
      this.robotConfiguration.splice(index, 1)
      this.result = ''
      this.error = ''
    }
  }

  private submit () {
    const moduleString = this.robotConfiguration.map((mapping: Mapping) => { return mapping.id }).join('-')
    // this.axios.get('calculate/all/106-2-2-20-2-28-1-1').then((response) => {
    this.axios.get(`calculate/all/${moduleString}`).then((response) => {
      this.error = ''
      this.result = response.data
    }).catch((error) => {
      console.error(error)
      if (error.response.status === 501) {
        this.error = 'invalid configuration. please try again'
      } else {
        this.error = 'internal error. please come back later!' + error
      }
    })
  }
}

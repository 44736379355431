import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import Dropdown from 'vue-simple-search-dropdown'
import 'typeface-roboto/index.css'

Vue.config.productionTip = false

const username = localStorage.getItem('username')
const password = localStorage.getItem('password')

Vue.use(VueAxios, axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? 'https://api.robco.de' : 'http://localhost:8000'
}))

Vue.use(Dropdown)

new Vue({
  render: h => h(App)
}).$mount('#app')
